.work {
    padding: 100px 60px 60px 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; /* Center content horizontally */

    h1 {
        width: auto;
        position: relative;
        display: inline-block; /* Ensures width fits content */
        text-transform: uppercase;
        white-space: nowrap; /* Prevents wrapping */
    }

    h1::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        left: 0;
        bottom: -10px;
        background: currentColor;
        opacity: 0;
        transition: opacity 0.3s ease;
        animation: underlineSlide 1s forwards;
        animation-delay: 0.5s;
    }
}



.work-container { 
  display: grid;
  grid-template-columns: repeat(3, 1fr); 
  gap: 20px; 
  padding-top: 80px;
  align-content: center;
  justify-content: center;
  align-items: center;
 justify-items: center;
}

.project-card {
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: stretch; 
  max-width: 400px;
  max-height: 400px;
  aspect-ratio: 1 / 1;

  
}

.project-card:hover .project-image {
  transform: scale(0.999) translate(-50%, 50%); 
  transform-origin: bottom left; 
  transition: transform 0.3s ease-in-out;
}

.project-image-wrapper {
  position: relative;
  flex-grow: 1; 
  background-color: white;

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Dark overlay */
    opacity: 0.3;
    transition: opacity 0.3s ease-in-out; /* Smooth transition */
    z-index: 1; /* Ensures it's above the image but below the hover content */
  }
}

.project-hover {
  display: grid;
  grid-template-rows: 50% auto;
  grid-template-columns: 1fr 1fr; 
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: white;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.project-card:hover .project-hover {
  opacity: 1; 
}

.project-title {
  grid-row: 1; 
  grid-column: 1 / span 2; /* Full width */
  display: flex;
  flex-direction: column;
  justify-content: center; 
  align-items: flex-start; 
  padding: 20px;
  width: 100%; 
  background-color: #000;
}

.project-title h2 {
  margin: 0;
  font-size: 1.5rem;
  margin-bottom: 10px; 
}

.project-title p {
  margin: 0;
  font-size: 1rem;
}

.project-link {
  grid-row: 2; 
  grid-column: 2; 
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: white;
  color: #000;
  .arrow-icon{
    width: 10px;
  }
}

.project-link:hover {
  color: #3e7590;
}

.arrow {
  font-size: 1.2rem;
  margin-left: 5px;
}

.project-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image fills the container without distortion */
  display: block;
  transition: transform 0.3s ease-in-out;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .work-container {
    grid-template-columns: repeat(2, 1fr); /* 2 columns for medium screens */
  }
}

@media (max-width: 480px) {
  .work-container {
    grid-template-columns: 1fr; /* 1 column for small screens */
  }
}

@media screen and (max-width: 1024px) {
  .work-container{
    padding-top: 20px;
  }
  .work h1{
    padding-top: 50px;
  }
}

