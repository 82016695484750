.toggle-container {
  display: grid;
  position: absolute;
  top: 40px;
  left: 40px;
  place-content: center;
  padding: 1rem;
  transition: background-color 0.3s;
  z-index: 2000;
  
  // &.light {
  //   background-color: white;
  // }
  
  // &.dark {
  //   background-color: #1e293b;
  // }
}

.slider-container {
  position: relative;
  display: flex;
  width: fit-content;
  align-items: center;
  border-radius: 9999px;
}

.toggle-button {
  text-sm: font-size;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1rem;
  transition: color 0.3s;
  position: relative;
  z-index: 10;
  border: none;
  background: none;
  cursor: pointer;
}

.selected-light {
  color: rgb(0, 0, 0);
  
}

.unselected-light {
  color: #ffffff;
}

.selected-dark {
  color: rgb(0, 0, 0);
}

.unselected-dark {
  color: #ffffff;
}

.slider-background {
  position: absolute;
  inset: 0;
  z-index: 0;
  display: flex;
  border-radius: 9999px;
}

.dark-mode {
  justify-content: flex-end;
}

.light-mode {
  justify-content: flex-start;
}

.slider {
  height: 100%;
  width: 50%;
  border-radius: 9999px;
  background: linear-gradient(to right, #0fc281, #38f60c);
}


@media screen and (max-width: 1023px) {
  .toggle-container {
    top: 100px;
    left: 50%; /* Moves it to the center */
    transform: translateX(-50%); 
  }
  .toggle-button{
    padding: 1.2rem 1.8rem;
  }
  .toggle-container {
    z-index: 0;
    }

}