
.home {
    .stack {
    display: grid;
    grid-template-columns: 1fr;
}



.hoverText {
  transition:
    0.35s font-weight,
    0.35s color;
}

.hoverText:hover {
  font-weight: 900;
  color: rgb(238, 242, 255);
}

/* To the right */
.hoverText:hover + .hoverText {
  font-weight: 500;
  color: rgb(199, 210, 254);
}

.hoverText:hover + .hoverText + .hoverText {
  font-weight: 300;
}

/* To the left */
.hoverText:has(+ .hoverText:hover) {
  font-weight: 500;
  color: rgb(199, 210, 254);
}

.hoverText:has(+ .hoverText + .hoverText:hover) {
  font-weight: 300;
}

.stack span {
  font-weight: bold;
  grid-row-start: 1;
  grid-column-start: 1;
  font-size: 5rem; 
  --stack-height: calc(100% / var(--stacks) - 1px);
  --inverse-index: calc(calc(var(--stacks) - 1) - var(--index));
  --clip-top: calc(var(--stack-height) * var(--index));
  --clip-bottom: calc(var(--stack-height) * var(--inverse-index));
  clip-path: inset(var(--clip-top) 0 var(--clip-bottom) 0);
  animation: stack 340ms cubic-bezier(.46,.29,0,1.24) 1 backwards calc(var(--index) * 120ms),
    glitch 2s ease infinite 2s alternate-reverse;
}

.stack span:nth-child(odd) {
  --glitch-translate: 8px;
}
.stack span:nth-child(even) {
  --glitch-translate: -8px;
}

@keyframes stack {
  0% {
    opacity: 0;
    transform: translateX(-50%);
    text-shadow: -2px 3px 0 red, 2px -3px 0 blue;
  }
  60% {
    opacity: 0.5;
    transform: translateX(50%);
  }
  80% {
    transform: none;
    opacity: 1;
    text-shadow: 2px -3px 0 red, -2px 3px 0 blue;
  }
  100% {
    text-shadow: none;
  }
}

@keyframes glitch {
  0% {
    text-shadow: -2px 3px 0 red, 2px -3px 0 blue;
    transform: translate(var(--glitch-translate));
  }
  2% {
    text-shadow: 2px -3px 0 red, -2px 3px 0 blue;
  }
  4%,
  100% {
    text-shadow: none;
    transform: none;
  }
}

h1 {
  font-size: 5rem;
  font-weight: 800;
  text-transform: uppercase;
  display: flex;
  gap: 10px; // Adjust spacing between "Hey, I'm" and "Tina"
  align-items: center;
  color: inherit;

//   .stack {
//     display: inline-block;
//   }
}

    .fade-exit {
        opacity: 1;
    }

    .fade-exit.fade-exit-active {
        opacity: 0;
        transition: opacity 500ms ease-out;
    }

    .wrap {
        padding-top: 0;
        padding-bottom: 0;
        z-index: 10;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100vh;
        position: relative;

        .home-inner {
            display: flex;
            flex-direction: column;
            align-items: center;

            h1 {
                font-size: 5rem;
                font-weight: 800;
                text-transform: uppercase;
                color: inherit;
                // background-color: transparent;
                // text-shadow: -4px -1px 0 #000, 2px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
                line-height: 1;

            }

            p {
                font-size: 2.8rem;
                margin-bottom: 10px;
            }

            .description {
                font-size: 1.8rem;
            }

        }

        .arrow-link {
            color: white;
            text-decoration: none;
            position: relative;
            padding-left: 24px;
            z-index: 10000;
        }

        .arrow-link::before {
            content: '→';
            position: absolute;
            left: 0;
            top: 0;
            color: white;
            font-size: 16px;
        }

        .link-wrapper {
            display: flex;
            flex-direction: row;
            gap: 30px;
        }

        .arrow-link::after {
            content: '';
            position: absolute;
            width: 110%;
            height: 2px;
            bottom: -7px;
            left: -5%;
            background-color: white;
            transition: transform 0.3s ease-in-out;
            transform: scaleX(0);
            transform-origin: left center;
        }

        .arrow-link:hover::after {
            transform: scaleX(1.1);
        }


        .arrow-link:hover::after {
            width: 100%;
        }





    }

}

@media screen and (max-width: 1024px) {
    .home {

        h1 {
            font-size: 3rem !important;


        }

        p {
            font-size: 2.5rem !important;
            text-align: center;
        }

        .description {
            font-size: 1.5rem !important;
        }

    }

}