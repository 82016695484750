.contact {
    .contact-inner {
        z-index: 10;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;

        height: 100vh;

        @keyframes underlineSlide {
            from {
                width: 0;
                opacity: 0;
            }

            to {
                width: 100%;
                opacity: 1;
            }
        }

        h1 {
            font-size: 5rem;
            position: relative;
            display: inline-block;
            text-transform: uppercase;
        }

        h1::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 2px;
            left: 0;
            bottom: -10px;
            background: currentColor;
            opacity: 0;
            transition: opacity 0.3s ease;
            animation: underlineSlide 1s forwards;
            animation-delay: 0.5s;
        }

        .link-wrapper {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            h2 {
                text-transform: uppercase;
                font-size: 2rem;
            }

            .mail-wrapper,
            .social-wrapper {
                display: flex;
                flex-direction: row;
                gap: 10px;

                .social-wrapper-inner {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                }
            }
        }



    }
}

@media screen and (max-width: 1024px) {
    .contact {

        h1 {
            font-size: 3rem !important;
            // margin-top: 400px;

        }

        h2 {
            font-size: 1.7rem !important;
        }

        .mail-wrapper,
        .social-wrapper {
            a {
                font-size: 1.4rem !important;
            }
        }

        .contact-inner .link-wrapper {
            flex-direction: column;
        }
    }
}