@import url('https://fonts.googleapis.com/css2?family=Platypi:ital,wght@0,300..800;1,300..800&display=swap');


html {
    font-size: 10px;

}

html,
body {
    padding-top: 70px;
    height: 100%;
    margin: 0;
    padding: 0;
    // overflow-x: hidden;
}


.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 999;
  background: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/86186/crt.png');
  background-size: cover;
  opacity: 0.3; 
}


@keyframes glitch {
  0% {
    transform: translate(0, 0);
    clip-path: inset(20% 0 60% 0);
  }
  10% {
    transform: translate(-5px, 5px);
    clip-path: inset(40% 0 30% 0);
  }
  20% {
    transform: translate(5px, -5px);
    clip-path: inset(10% 0 80% 0);
  }
  30% {
    transform: translate(-10px, 10px);
    clip-path: inset(30% 0 50% 0);
  }
  40% {
    transform: translate(10px, -10px);
    clip-path: inset(70% 0 10% 0);
  }
  50% {
    transform: translate(-15px, 15px);
    clip-path: inset(20% 0 60% 0);
  }
  60% {
    transform: translate(15px, -15px);
    clip-path: inset(50% 0 20% 0);
  }
  70% {
    transform: translate(-5px, 5px);
    clip-path: inset(10% 0 80% 0);
  }
  80% {
    transform: translate(5px, -5px);
    clip-path: inset(40% 0 30% 0);
  }
  100% {
    transform: translate(0, 0);
    clip-path: inset(20% 0 60% 0);
  }
}

@keyframes glitch-text {
  0% {
    text-shadow: 1px -2px 1px #00ff00, -2px 2px 1px #ff0000;
    transform: translate(0, 0);
  }
  50% {
    text-shadow: -1px 2px 1px #ff0000, 1px -2px 1px #00ff00;
    transform: translate(-1px, 1px);
  }
  100% {
    text-shadow: 0px 0px 1px #00ff00, -1px -1px 1px #ff0000;
    transform: translate(1px, -1px);
  }
}

.glitch {
  display: inline-block;
  position: relative;
  animation: glitch-text 1s infinite alternate;
  text-shadow: 0px 0px 1px #00ff00, -1px -1px 1px #ff0000;
}

html,
body,
#root,
.App {
    height: 100%;
    // background-color: #1D1D1D;
    // overflow-x: hidden;

    &.no-animation {
      height: auto;
      background-color: #000000;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%23a20578' fill-opacity='0.74' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E");
       
        .scrolled {
        background: rgba( 46, 38, 38, 0.25 );
        box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
        backdrop-filter: blur( 7.5px );
        -webkit-backdrop-filter: blur( 7.5px );
        border-radius: 10px;
        border: 1px solid rgba( 255, 255, 255, 0.18 );
        .nav-links {
          list-style: none;
          display: flex;
          gap: 20px;
          li {
            a {
               color: #a09c9c;
            }
          }
          li {
        a {
          &:hover {
            color: #b7ccd3;
          }

          &.active {
            color: #ffffff;
            font-weight: bold;
          }
        }
      }
       }
    }
      }
}

.wrapping {
    margin: 0 150px;
}

*,
::before,
::after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

body {
    font-family: "Platypi", serif;
    font-optical-sizing: auto;
    font-style: normal;
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 1;
    background-color: #282828;
    color: #c6befc;

}

.page-enter {
    opacity: 0;
    transform: translateX(100%);
}

.page-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms ease-in, transform 300ms ease-in;
}

.page-exit {
    opacity: 1;
}

.page-exit-active {
    opacity: 0;
    transition: opacity 300ms ease-in;
}


@mixin dots($count) {
    $text-shadow: ();

    @for $i from 0 through $count {
        $text-shadow: $text-shadow,
            (-.5+(random()) * 3) + em (-.5+(random()) * 3) + em 7px hsla(random() * 360, 100%, 50%, .9);
    }

    text-shadow: $text-shadow;
}





a,
a:hover {
    -webkit-transition: all .4 ease-in-out;
    -moz-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;

}

canvas {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

@media screen and (max-width: 1023px) {
    .wrapping {
        margin: 0px 40px 40px 40px;
    }

    .about .about-inner {
        width: 100%;
        height: 110%;

    }


}



// @media (min-width: 768px) {

//     body,
//     a:hover {
//         cursor: none;
//         /* Disable the default cursor on non-mobile devices */
//     }

//     .dot {
//         position: fixed;
//         pointer-events: none;
//         height: 10px;
//         width: 10px;
//         background-color: #f95e4a;
//         border-radius: 50%;
//         transform: translate(-50%, -50%);
//         transition: transform 0.2s, width 0.2s, height 0.2s;
//         z-index: 10000;
//     }

//     .outer-circle {
//         position: fixed;
//         pointer-events: none;
//         top: 0;
//         left: 0;
//         width: 50px;
//         height: 50px;
//         background-color: #f95e4a;
//         opacity: 0.3;
//         border-radius: 50%;
//         transform: translate(-50%, -50%);
//         transition: left 0.3s ease-out, top 0.3s ease-out;
//         z-index: 9999;
//     }

//     .outer-circle.hovered {
//         width: 70px;
//         height: 70px;
//         transition: width 0.3s ease, height 0.3s ease;
//     }
// }