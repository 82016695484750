.navbar {
 width: 100%;
  top: 20px;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  background-color: transparent;
  transition: background-color 0.3s ease; 

    @media screen and (max-width: 1023px) {
    .nav-links {
      gap: 10px !important;
    }
  }

  
.navbar-inner {
    width: auto;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    border: 1px solid #fff;
    border-radius: 10px;

    &.scrolled {
      background: rgba( 46, 38, 38, 0.25 );
      box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
      backdrop-filter: blur( 7.5px );
      -webkit-backdrop-filter: blur( 7.5px );
      border-radius: 10px;
      border: 1px solid rgba( 255, 255, 255, 0.18 );
      .nav-links {
        list-style: none;
        display: flex;
        gap: 20px;
        li {
          a {
            color: #000000;;
          }
        } }

      }

    .logo {
      .icon {
        width: 40px;
        color: #fff;
        text-decoration: none;
      }
    }

    .nav-links {
      list-style: none;
      display: flex;
      gap: 20px;

      li {
        a {
          text-decoration: none;
          font-size: 1.5rem;
          color: #a09c9c;
          transition: color 0.3s;

          &:hover {
            color: #b7ccd3;
          }

          &.active {
            color: #ffffff;
            font-weight: bold;
          }
        }
      }
    }
  }
}


