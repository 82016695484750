.about {
    // background-color: #1D1D1D;
    // color: #fff;
    position: relative;
    padding-top: 77px;

    .img-inner-wrapper::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.4);
        border-radius: 0px 0px 0px 100px;
    }

    .img-wrapper {
        position: absolute;
        top: 0;
        right: 0px;

        img {
            max-width: 100%;
            min-width: 500px;
            height: auto;
            border-radius: 0px 0px 0px 100px;
        }
    }

    .about-inner {
        z-index: 10;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 40%;
        height: 100%;
        padding-bottom: 100px;

        .skills{
            display: flex;
            flex-direction: row;
            gap: 20px;
            flex-wrap: wrap;

            .icon-wrapper {
                position: relative;
                cursor: pointer;

                .tooltip {
                position: absolute;
                bottom: 110%; 
                left: 50%;
                transform: translateX(-50%);
                padding: 5px 10px;
                background: #000000;
                color: #fff;
                font-size: 12px;
                border-radius: 4px;
                white-space: nowrap;
                opacity: 0; 
                visibility: hidden; 
                transition: opacity 0.3s ease, visibility 0.3s ease;
                }

                &:hover .tooltip {
                opacity: 1;
                visibility: visible; 
                }
            }
        }

        @keyframes underlineSlide {
            from {
                width: 0;
                opacity: 0;
            }

            to {
                width: 100%;
                opacity: 1;
            }
        }

        h1 {
            font-size: 5rem;
            position: relative;
            display: inline-block;
            text-transform: uppercase;
        }
        h2{
            position: relative;
            display: inline-block;
            text-transform: uppercase;
            padding-top: 20px;
        }

        h2::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 2px;
            left: 0;
            bottom: -10px;
            background: currentColor;
            opacity: 0;
            transition: opacity 0.3s ease;
            animation: underlineSlide 1s forwards;
            animation-delay: 0.5s;
        }

        h1::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 2px;
            left: 0;
            bottom: -10px;
            background: currentColor;
            opacity: 0;
            transition: opacity 0.3s ease;
            animation: underlineSlide 1s forwards;
            animation-delay: 0.5s;
        }


        p {
            font-size: 2.3rem;
        }

        .arrow-link {
            color: white;
            text-decoration: none;
            position: relative;
            padding-left: 24px;
            z-index: 10000;
            font-size: 1.8rem;
        }


        .arrow-link::before {
            content: '→';
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%) rotate(90deg);
            color: white;
            font-size: 16px;
        }

        .arrow-link::after {
            content: '';
            position: absolute;
            width: 110%;
            height: 2px;
            bottom: -7px;
            left: -5%;
            background-color: white;
            transition: transform 0.3s ease-in-out;
            transform: scaleX(0);
            transform-origin: left center;
        }

        .arrow-link:hover::after {
            transform: scaleX(1.1);
        }


        .arrow-link:hover::after {
            width: 100%;
        }


        // .skills {
        //     position: relative;
        //     display: flex;
        //     flex-direction: column;
        //     justify-content: center;
        //     align-items: center;


        //     span {
        //         padding: 1rem;
        //         font-size: 1.5rem;
        //         font-weight: 700;

        //     }
        // }
    }
}

@media screen and (max-width: 1024px) {
    .about {
        
        .about-inner {
            width: 100%;
        }

        .img-inner-wrapper::before {
            content: '';
             display: none;
            border-radius: 10px 10px 10px 10px;
        }

        .img-wrapper {
            position: absolute;
            top: 180px;
            left: 55%;
            width: 300px;
            height: 300px;
            transform: translateX(-50%);

            img {

                min-width: auto;
                min-height: auto;
                max-height: 350px;
                border-radius: 10px 10px 10px 10px;
            }
        }


        h1 {
            font-size: 3rem !important;
            margin-top: 480px;

        }

        p {
            font-size: 1.5rem !important;

        }

        .description {
            font-size: 1.5rem !important;
        }

    }

}